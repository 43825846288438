import React from 'react';
import Project from '../../api/project'
import { Link } from "react-router-dom";
import { Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

const ProjectSection = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="portfolio_section xb-hidden section_space pb-0">
            <div className="container">
                <div className="heading_block">
                    <div className="row align-items-end">
                        <div className="col-lg-7">
                            <div className="heading_focus_text">
                                <span className="badge bg-secondary text-white">Projetos</span>
                                Cases 😍
                            </div>
                            <h2 className="heading_text">
                                Nossos melhores trabalhos recentes
                            </h2>
                            <p className="heading_description mb-0">
                                Nossos projetos recentes destacam nossa experiência em fornecer soluções sob medida que atendam às necessidades e objetivos exclusivos de nossos clientes, software customizado.
                            </p>
                        </div>
                        <div className="col-lg-5 d-none d-lg-flex justify-content-end">
                            <Link onClick={ClickHandler} to={'/portfolio'} className="btn btn-primary">
                                <span className="btn_label" data-text="Ver Todos">Ver Todos</span>
                                <span className="btn_icon">
                                    <i className="fa-solid fa-arrow-up-right"></i>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio_carousel">
                <Swiper
                    // install Swiper modules
                    modules={[Pagination, A11y]}
                    slidesPerView={1}
                    loop={true}
                    spaceBetween={30}
                    allowTouchMove={true}
                    centeredSlides={true}
                    pagination={{ clickable: true }}
                    speed={400}
                    parallax={true}
                    breakpoints={{
                        1025: {
                            slidesPerView: 2,
                        },
                    }}
                >

                    {Project.slice(0, 5).map((project, prj) => (
                        <SwiperSlide key={prj}>
                            <div className="portfolio_block">
                                <div className="portfolio_image">
                                    {
                                        project.slug.startsWith('http') ? (
                                            <a onClick={ClickHandler} className="portfolio_image_wrap bg-light" href={project.slug} target="_blank" rel="noopener noreferrer">
                                                <img src={project.pImg} alt="Mobile App Design" />
                                            </a>
                                        ) : (
                                            <Link onClick={ClickHandler} className="portfolio_image_wrap bg-light" to={`/portfolio_details/${project.slug}`}>
                                                <img src={project.pImg} alt="Mobile App Design" />
                                            </Link>
                                        )
                                    }
                                </div>
                                <div className="portfolio_content">
                                    <h3 className="portfolio_title">
                                        {
                                            project.slug.startsWith('http') ? (
                                                <a onClick={ClickHandler} href={project.slug} target="_blank" rel="noopener noreferrer">
                                                    {project.title}
                                                </a>
                                            ) : (
                                                <Link onClick={ClickHandler} to={`/portfolio_details/${project.slug}`}>
                                                    {project.title}
                                                </Link>
                                            )
                                        }
                                    </h3>
                                    <ul className="category_list unordered_list">
                                        {
                                            project.slug.startsWith('http') ? (
                                                <li><a onClick={ClickHandler} href={project.slug} target="_blank" rel="noopener noreferrer">{project.sub}</a></li>
                                            ) : (
                                                <li><Link onClick={ClickHandler} to={`/portfolio_details/${project.slug}`}>{project.sub}</Link></li>
                                            )
                                        }
                                    </ul>
                                    {
                                        project.slug.startsWith('http') ? (
                                            <a onClick={ClickHandler} className="btn btn-outline-light" href={project.slug} target="_blank" rel="noopener noreferrer">
                                                <span className="btn_label" data-text="Conhecer">Conhecer</span>
                                                <span className="btn_icon">
                                                    <i className="fa-solid fa-arrow-up-right"></i>
                                                </span>
                                            </a>
                                        ) : (
                                            <Link onClick={ClickHandler} className="btn btn-outline-light" to={`/portfolio_details/${project.slug}`}>
                                                <span className="btn_label" data-text="Conhecer">Conhecer</span>
                                                <span className="btn_icon">
                                                    <i className="fa-solid fa-arrow-up-right"></i>
                                                </span>
                                            </Link>
                                        )
                                    }
                                </div>
                            </div>
                        </SwiperSlide>

                    ))}
                </Swiper>
            </div>
            <div className="container text-center d-block d-lg-none">
                <div className="btns_group pb-0">
                    <Link onClick={ClickHandler} className="btn btn-primary" to="/planos-precos">
                        <span className="btn_label" data-text="Ver Todos">Ver Todos</span>
                        <span className="btn_icon">
                            <i className="fa-solid fa-arrow-up-right"></i>
                        </span>
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default ProjectSection;