import React from 'react';
import sIcon1 from '../../images/icons/icon_doc.svg';
import sIcon2 from '../../images/icons/icon_analysis.svg';
import sIcon3 from '../../images/icons/icon_headphone_2.svg';

const Policy = [
    {
        title: 'Documentação Completa',
        subTitle: 'Nossa documentação é abrangente e detalhada, garantindo que você tenha todas as informações necessárias.',
        icon: sIcon1,
    },
    {
        title: 'Entrega Rápida',
        subTitle: 'Nosso processo é focado na entrega rápida, garantindo que seu projeto seja finalizado no menor tempo possível.',
        icon: sIcon2,
    },
    {
        title: 'Suporte Vitalício',
        subTitle: 'Oferecemos suporte vitalício para garantir que seu produto ou serviço esteja sempre funcionando perfeitamente.',
        icon: sIcon3,
    },
];

const PolicySection = (props) => {
    return (
        <section className="policy_section section_space">
            <div className="container">
                <div className="heading_block">
                    <div className="row justify-content-lg-between">
                        <div className="col-lg-6">
                            <h2 className="heading_text mb-0">
                                Celebrando Funcionalidades Que Nos Diferenciam
                            </h2>
                        </div>
                        <div className="col-lg-5">
                            <p className="heading_description mb-0">
                                Nos orgulhamos de celebrar as funcionalidades que nos diferenciam e nos fazem um líder em soluções de e-commerce. O que realmente nos distingue é nosso compromisso inabalável com a inovação, excelência e satisfação do cliente.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row pricing_policy_wrap">
                    {Policy.map((policy, pitem) => (
                        <div className="col-lg-4" key={pitem}>
                            <div className="iconbox_block">
                                <div className="iconbox_icon">
                                    <img src={policy.icon} alt="Ícone SVG" />
                                </div>
                                <div className="iconbox_content">
                                    <h3 className="iconbox_title">{policy.title}</h3>
                                    <p className="mb-0">
                                        {policy.subTitle}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PolicySection;
