import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Homepage from '../HomePage/HomePage'
import HomePage2 from '../HomePage2/HomePage2';
import HomePage3 from '../HomePage3/HomePage3';
import AboutUsPage from '../AboutUsPage/AboutUsPage';
import PricingPage from '../PricingPage/PricingPage';
import PortfolioPage from '../PortfolioPage/PortfolioPage';
import PortfolioSinglePage from '../PortfolioSinglePage/PortfolioSinglePage';
import TeamPage from '../TeamPage/TeamPage';
import TeamSinglePage from '../TeamSinglePage/TeamSinglePage';
import ServicePage from '../ServicePage/ServicePage';
import ServiceSinglePage from '../ServiceSinglePage/ServiceSinglePage';
import BlogPage from '../BlogPage/BlogPage';
import BlogDetails from '../BlogDetails/BlogDetails';
import ContactPage from '../ContactPage/ContactPage';
import NotFoundPage from '../App/NotFoundPage';

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/2" element={<HomePage2 />} />
          <Route path="/3" element={<HomePage3 />} />
          <Route path="sobre" element={<AboutUsPage />} />
          <Route path="contato" element={<ContactPage />} />  
          <Route path="planos-precos" element={<PricingPage />} />
          <Route path="servicos" element={<ServicePage />} />
          <Route path="*" element={<NotFoundPage />} /> {/* Rota 404 */}


        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
