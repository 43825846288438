import React from 'react';
import sIcon1 from '../../images/icons/icon_user_check.svg';
import sIcon2 from '../../images/icons/icon_headphone.svg';
import sIcon3 from '../../images/icons/icon_dollar.svg';

const Policy = [
    {
        title: 'Equipe de especialistas em e-commerce',
        subTitle: 'Nossa equipe é composta por profissionais com vasta experiência no mercado digital.',
        icon: sIcon1,
    },
    {
        title: 'Suporte ao cliente ágil',
        subTitle: 'Garantimos um atendimento ao cliente rápido e eficiente, sempre que você precisar.',
        icon: sIcon2,
    },
    {
        title: 'Preços competitivos',
        subTitle: 'Oferecemos preços justos e acessíveis, garantindo excelente custo-benefício.',
        icon: sIcon3,
    },
];

const PolicySection = (props) => {
    return (
        <section className="policy_section">
            <div className="container">
                <div className="row">
                    {Policy.map((policy, pitem) => (
                        <div className="col-lg-4" key={pitem}>
                            <div className="iconbox_block layout_icon_left">
                                <div className="iconbox_icon bg-secondary-subtle">
                                    <img src={policy.icon} alt={`${policy.title} Icon`} />
                                </div>
                                <div className="iconbox_content">
                                    <h3 className="iconbox_title">{policy.title}</h3>
                                    <p className="mb-0">
                                        {policy.subTitle}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PolicySection;
