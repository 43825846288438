import React from 'react';
import sIcon1 from '../../images/icons/icon_clock.svg'
import sIcon2 from '../../images/icons/icon_dart_board_2.svg'
import sIcon3 from '../../images/icons/icon_target.svg'

const Policy = [
    {
        title: 'Nossa História',
        subTitle: 'Desde 2023, somos parceiros confiáveis de e-commerce, ajudando marcas a alcançarem seus objetivos de vendas online com soluções sob medida e suporte de alta qualidade.',
        icon: sIcon1,
    },
    {
        title: 'Nossa Missão',
        subTitle: 'Nossa missão é capacitar negócios de todos os tamanhos com soluções de e-commerce inovadoras, tornando o comércio digital acessível e eficiente para todos.',
        icon: sIcon2,
    },
    {
        title: 'Nossa Visão',
        subTitle: 'Ser reconhecidos como a principal plataforma de e-commerce no Brasil, oferecendo as melhores ferramentas para que nossos clientes cresçam e prosperem no ambiente digital.',
        icon: sIcon3,
    },
]

const PolicySection = (props) => {
    return (
        <section className="policy_section bg-light">
            <div className="container">
                <div className="row">
                    {Policy.map((policy, pitem) => (
                        <div className="col-lg-4" key={pitem}>
                            <div className="iconbox_block">
                                <div className="iconbox_icon">
                                    <img src={policy.icon} alt={policy.title} />
                                </div>
                                <div className="iconbox_content">
                                    <h3 className="iconbox_title">{policy.title}</h3>
                                    <p className="mb-0">
                                        {policy.subTitle}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PolicySection;
