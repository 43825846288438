import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import CtaSection from '../../components/CtaSection/CtaSection';
import srImg from '../../images/services/irineu.png';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import WhyUs from '../AboutUsPage/WhyUs';
import { Helmet } from 'react-helmet';

const ServicePage = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <Fragment>
            <Helmet>
                <title>Serviços - RodCommerce - Soluções Flexíveis para Seu E-commerce</title>
                <meta name="description" content="RodCommerce oferece soluções dinâmicas e flexíveis para e-commerce, ajustando serviços às suas necessidades específicas." />
                <meta name="keywords" content="RodCommerce, e-commerce, soluções flexíveis, desenvolvimento de software, serviços, services, service, serviço" />

                <meta property="og:title" content="Serviços - RodCommerce - Soluções Flexíveis para Seu E-commerce" />
                <meta property="og:description" content="Na RodCommerce, oferecemos soluções dinâmicas para atender às suas necessidades de e-commerce." />
                <meta property="og:image" content="https://rodcommerce.com/og.png" />
                <meta property="og:url" content="https://www.rodcommerce.com.br/servicos" />
                <meta property="og:type" content="website" />


                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Serviços - RodCommerce - Soluções Flexíveis para Seu E-commerce" />
                <meta name="twitter:description" content="Conheça as soluções de e-commerce da RodCommerce." />
                <meta name="twitter:image" content="https://rodcommerce.com/og.png" />
                <link rel="canonical" href="https://www.rodcommerce.com.br/servicos" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Nossos Serviços'} pagesub={'Serviços 😍'} pageTop={'Principais'} />
                <section className="about_section section_space bg-light">
                    <div className="container">
                        <div className="row align-items-center justify-content-lg-between">
                            <div className="col-lg-5 order-lg-last">
                                <div className="team_cartoon_image">
                                    <img src={srImg} alt="RodCommerce Service" />
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="about_content">
                                    <div className="heading_block">
                                        <div className="heading_focus_text">
                                            Somos
                                            <span className="badge bg-secondary text-white">Especialistas em E-commerce 🙂</span>
                                        </div>
                                        <h2 className="heading_text">
                                            Soluções de E-commerce Sob Medida para o Seu Sucesso
                                        </h2>
                                        <p className="heading_description mb-0">
                                            Na RodCommerce, entendemos que cada negócio é único, com seus próprios desafios, metas e aspirações. Por isso, oferecemos soluções de e-commerce personalizadas para impulsionar seu sucesso.
                                        </p>
                                    </div>
                                    <Link onClick={ClickHandler} to={'/contato'} className="btn">
                                        <span className="btn_label" data-text="Fale com um Especialista">Fale com um Especialista</span>
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-arrow-up-right"></i>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ServiceSection />
                <div className="pt-130"></div>
                <WhyUs />
            </main>
            <CtaSection />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default ServicePage;
