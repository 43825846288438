import React from 'react';
import mrstylish from '../../images/cases/mrstylishstore.com3.png'
import Bg from '../../images/shapes/aaa.png'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"



const testimonial = [
    {
        id: '01',
        tImg: mrstylish,  // Supondo que tImg1 seja uma imagem associada à Kathleen Silva
        Title: 'Eficiência Total na Gestão do Seu E-commerce',
        Des: "O MrStylishStore.com, criado na plataforma RodCommerce, revolucionou a forma como gerencio meu negócio online. A plataforma é extremamente fácil de usar e proporciona todas as ferramentas necessárias para gerir um e-commerce com eficiência. Desde a gestão de produtos até o processamento de pedidos, tudo é feito de maneira intuitiva, o que torna meu trabalho muito mais simples e ágil.",
        Name: 'Kathleen M. Silva',
        sub: 'Empresária',
    }
];



const Testimonial = () => {

    return (

        <section className="review_section section_space bg-light" style={{ backgroundImage: `url(${Bg})` }}>
            <div className="container">
                <div className="heading_block text-center">
                    <div className="heading_focus_text">
                        <span className="badge bg-secondary text-white">Cliente</span>
                        Depoimento 🙂
                    </div>
                    <h2 className="heading_text mb-0">
                        O que os clientes dizem
                    </h2>
                </div>

                <div className="row">
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 1, 991: 2 }}>
                        <Masonry columnsCount={4} gutter="30px">
                            {testimonial.map((testimonial, tsm) => (
                                <div className="review_block pb-0" key={tsm}>
                                    <h3 className="review_title">“{testimonial.Title}”</h3>
                                    <p className="review_commtent">
                                        {testimonial.Des}
                                    </p>
                                    <div className="review_admin">
                                        <div className="review_admin_image">
                                            <img src={testimonial.tImg} alt="Roman Dymtro" />
                                        </div>
                                        <div className="review_admin_info">
                                            <h4 className="review_admin_name">{testimonial.Name}</h4>
                                            <span className="review_admin_designation">{testimonial.sub}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;