import React from 'react';
import { Link } from 'react-router-dom';
import Bg from '../../images/shapes/bg_pattern_3.svg';
import icon1 from '../../images/icons/icon_mail.svg';
import icon2 from '../../images/icons/icon_calling.svg';
import icon3 from '../../images/icons/icon_map_mark.svg';
import icon4 from '../../images/icons/icon_mail_2.svg';
import Services from '../../api/service';

const ClickHandler = () => {
    window.scrollTo(10, 0);
};

const SubmitHandler = (e) => {
    e.preventDefault();
};

const Footer = (props) => {
    return (
        <footer className="site_footer footer_layout_1">
            <div className="content_box" style={{ backgroundImage: `url(${Bg})` }}>
                <div className="container">
                    <div className="diract_contact_links text-white d-flex justify-content-between mb-4">
                        <div className="iconbox_block layout_icon_left">
                            <div className="iconbox_icon">
                                <img src={icon1} alt="Ícone de Email" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Escreva para nós</h3>
                                <p className="mb-0">contato@rodcommerce.com</p>
                            </div>
                        </div>
                        <div className="iconbox_block layout_icon_left">
                            <div className="iconbox_icon">
                                <img src={icon2} alt="Ícone de Chamada" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Ligue para nós</h3>
                                <p className="mb-0">(44) 99758-6617</p>
                            </div>
                        </div>
                        <div className="iconbox_block layout_icon_left">
                            <div className="iconbox_icon">
                                <img src={icon3} alt="Ícone de Localização" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Local</h3>
                                <p className="mb-0">Maringá - PR</p>
                            </div>
                        </div>
                    </div>
                    <div className="footer_main_content">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="footer_widget pe-md-3">
                                    <h2 className="footer_info_title">Newsletter</h2>
                                    <p>Inscreva-se na newsletter semanal da RodCommerce para receber as últimas atualizações.</p>
                                    <form className="footer_newsletter" onSubmit={SubmitHandler}>
                                        <div className="input-group">
                                            <span className="input-group-text" id="footer_mail_input">
                                                <img src={icon4} alt="Ícone de Email" />
                                            </span>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Digite seu email"
                                                aria-label="Email"
                                                aria-describedby="footer_mail_input"
                                            />
                                            <button className="btn btn-outline-light" type="submit">
                                                <i className="fa-solid fa-paper-plane"></i>
                                            </button>
                                        </div>
                                    </form>
                                    <ul className="social_links_block unordered_list d-flex mt-3">
                                        <li><Link onClick={ClickHandler} to="/">Facebook</Link></li>
                                        <li><Link onClick={ClickHandler} to="/">Twitter</Link></li>
                                        <li><Link onClick={ClickHandler} to="/">LinkedIn</Link></li>
                                    </ul>
                                </div>
                            </div>
                           {
                            /*
                             <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="footer_widget">
                                    <h3 className="footer_info_title">Serviços</h3>
                                    <ul className="icon_list unordered_list_block">
                                        {Services.slice(0, 6).map((service, srv) => (
                                            <li key={srv}>
                                                {service.title && (
                                                    <a
                                                        onClick={ClickHandler}
                                                        href={`${service.url}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <span className="icon_list_text">{service.title}</span>
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>*/
                           }
                            <div className="col-lg-2 col-md-6 col-sm-12">
                                <div className="footer_widget">
                                    <h3 className="footer_info_title">Informações</h3>
                                    <ul className="icon_list unordered_list_block">
                                        <li>
                                            <Link onClick={ClickHandler} to="/sobre">
                                                <span className="icon_list_text">Sobre</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/contato">
                                                <span className="icon_list_text">Contato</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/contato">
                                                <span className="icon_list_text">Conhecer</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="footer_widget">
                                    <h3 className="footer_info_title">Informações da Empresa</h3>
                         
                                        <p><span className="infoBusiness">Nome: RodCommerce Solutions LTDA</span><br/>
                                        <span className="infoBusiness"><b>CNPJ</b>: 12.345.678/0001-99</span><br/>
                                        <span className="infoBusiness"><b>Endereço</b>: Rua Haiti, 815, Vila Morangueira, Maringá - PR, 87040-420</span><br/>
                                        <span className="infoBusiness"><b>Telefone</b>: (44) 99758-6617</span><br/>
                                        <span className="infoBusiness"><b>E-mail</b>: contato@rodcommerce.com.br</span></p>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_bottom bg-dark text-white py-3">
                <div className="container d-md-flex align-items-md-center justify-content-md-between">
                    <p className="m-0">
                        Copyright © 2024 RodCommerce, Todos os direitos reservados.
                    </p>
                    <p className="m-0">
                        Desenvolvido por <Link onClick={ClickHandler} to="/">RodCommerce</Link>
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
