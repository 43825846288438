import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom'
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import ModalVideo from 'react-modal-video'
import CtaSection from '../../components/CtaSection/CtaSection';
import PolicySection from './Policy';
import offerBanner from '../../images/icons/best_offer.svg.png';
import PartnerSection from '../../components/PartnerSection';
import { Helmet } from 'react-helmet';


const PricingPage = (props) => {

    const [isOpen, setOpen] = useState(false)

    const [isActive, setIsActive] = useState(false);

    const handleToggle = () => {
        setIsActive(!isActive);
    };


    return (
        <Fragment>
            <Helmet>
                <title>Planos & Preços - RodCommerce - Soluções Flexíveis para Seu E-commerce</title>
                <meta name="description" content="RodCommerce oferece soluções dinâmicas e flexíveis para e-commerce, ajustando serviços às suas necessidades específicas." />
                <meta name="keywords" content="RodCommerce, e-commerce, soluções flexíveis, desenvolvimento de software, planos, precos, plans, prices" />

                <meta charset="UTF-8" />
                <meta name="robots" content="index, follow" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />

                <meta property="og:title" content="Planos & Preços - RodCommerce - Soluções Flexíveis para Seu E-commerce" />
                <meta property="og:description" content="Na RodCommerce, oferecemos soluções dinâmicas para atender às suas necessidades de e-commerce." />
                <meta property="og:image" content="https://rodcommerce.com/og.png" />
                <meta property="og:url" content="https://www.rodcommerce.com.br/planos-precos" />
                <meta property="og:type" content="website" />


                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Planos & Preços - RodCommerce - Soluções Flexíveis para Seu E-commerce" />
                <meta name="twitter:description" content="Conheça as soluções de e-commerce da RodCommerce." />
                <meta name="twitter:image" content="https://rodcommerce.com/og.png" />
                <link rel="canonical" href="https://www.rodcommerce.com.br/planos-precos" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Planos & Preços'} pagesub={'Planos 😍'} pageTop={'Nossos'} />
                <PolicySection />



                <section className="pricing_section section_space pb-0 bg-light">
                    <div className="container">
                        <div className="heading_block text-center">
                            <div className="heading_focus_text mb-2">
                                Nossos
                                <span className="badge bg-secondary text-white">Preços 😍</span>
                            </div>
                            <h2 className="heading_text mb-0">
                                Melhor Plano para Negócios
                            </h2>
                        </div>
                        <div className="pricing_toggle_btn text-center">
                            <button type="button" onClick={handleToggle} className={isActive ? 'active' : ''}>
                                <span>Cobrado Mensalmente <small>-10%</small></span>
                                <span>Cobrado Anualmente <small>-30%</small></span>
                            </button>
                        </div>
                        <div className="row justify-content-center">
                            {/* Plano Básico */}
                            <div className="col-lg-6">
                                <div className={`pricing_block ${isActive ? 'active' : ''}`}>
                                    <div className="table_head">
                                        <div className="pricing_price_value bg-primary-subtle text-primary">
                                            <span className="pricing_monthly">
                                                <del>R$ 120,00</del> R$ 99<small>,90</small> <sub>Você economiza <u>R$ 20,10</u> por mês</sub>
                                            </span>
                                            <span className="pricing_annually">
                                                <del>R$ 1.440,00</del> R$ 1.069<small>,00</small> <sub>Você economiza <u>R$ 371,00</u> por ano</sub>
                                            </span>

                                        </div>
                                        <div className="pricing_block_title">
                                            <h3 className="pricing_package_title">Pacote Básico</h3>
                                            <p className="pricing_package_description mb-0">
                                                Ideal para pequenos negócios que estão começando no e-commerce, com funcionalidades essenciais para operar e crescer.
                                            </p>
                                        </div>
                                    </div>
                                    <ul className="icon_list unordered_list_block">
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Até 100 produtos.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Até 10 mil acessos mensais.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Suporte 24/7 via e-mail.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Sistema flexível e parametrizável.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Integração com marketplaces.
                                            </span>
                                        </li>
                                        <li className="delete">
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Integrações de SEO básicas.
                                            </span>
                                        </li>
                                    </ul>
                                    <a className="btn btn-light" href="/contato">
                                        <span className="btn_label" data-text="Adquirir Agora">Adquirir Agora</span>
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-arrow-up-right"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            {/* Plano Pro */}
                            <div className="col-lg-6">
                                <div className={`pricing_block ${isActive ? 'active' : ''}`}>
                                    <div className="best_plan">
                                        <img src={offerBanner} alt="Melhor Oferta" />
                                    </div>
                                    <div className="table_head">
                                        <div className="pricing_price_value bg-primary-subtle text-primary">
                                            <span className="pricing_monthly">
                                                <del>R$ 250,00</del> R$ 199<small>,90</small> <sub>Você economiza <u>R$ 50,10</u> por mês</sub>
                                            </span>
                                            <span className="pricing_annually">
                                                <del>R$ 3.000,00</del> R$ 2.159<small>,00</small> <sub>Você economiza <u>R$ 841,00</u> por ano</sub>
                                            </span>
                                        </div>
                                        <div className="pricing_block_title">
                                            <h3 className="pricing_package_title">Pacote Pro</h3>
                                            <p className="pricing_package_description mb-0">
                                                Para empresas que desejam expandir e alcançar novos horizontes, com funcionalidades avançadas para maximizar resultados.
                                            </p>
                                        </div>
                                    </div>
                                    <ul className="icon_list unordered_list_block">
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Produtos ilimitados.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Acessos ilimitados.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Suporte 24/7 via telefone e e-mail.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Integração com marketplaces avançada.
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Integração de SEO completa (Google Merchant, etc.).
                                            </span>
                                        </li>
                                        <li>
                                            <span className="icon_list_icon">
                                                <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span className="icon_list_text">
                                                Relatórios e insights detalhados.
                                            </span>
                                        </li>
                                    </ul>
                                    <a className="btn btn-light" href="/contato">
                                        <span className="btn_label" data-text="Adquirir Agora">Adquirir Agora</span>
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-arrow-up-right"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="client_logo_section section_space bg-light">
                    <div className="container">
                        <div className="heading_block text-center">
                            <div className="heading_focus_text mb-0">
                                <span className="badge bg-secondary text-white">Tecnologias</span>
                                Linguagens usadas
                            </div>
                        </div>
                        <PartnerSection />
                    </div>
                </div>
            </main>
            <CtaSection />
            <Footer />
            <Scrollbar />
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} />
        </Fragment>
    )
};
export default PricingPage;
