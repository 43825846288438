import React from 'react'
import ContactForm from '../ContactFrom/ContactForm'
import icon1 from '../../images/icons/icon_map_mark_2.svg'
import icon2 from '../../images/icons/icon_calling_2.svg'
import icon3 from '../../images/icons/icon_mail_3.svg'
import icon4 from '../../images/icons/icon_calendar_2.svg'

const ContactSection = (props) => {
    return (
        <section className="contact_section section_space bg-light">
            <div className="container">
                <div className="contact_info_box row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="iconbox_block text-center">
                            <div className="iconbox_icon">
                                <img src={icon1} alt="Ícone de Localização" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Localização</h3>
                                <p className="mb-0">
                                    R. Haiti, 815, Vila Morangueira, Maringá - PR, 87040-420
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="iconbox_block text-center">
                            <div className="iconbox_icon">
                                <img src={icon2} alt="Ícone de Telefone" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Contato</h3>
                                <p className="mb-0">+55 (44) 99758-6617</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="iconbox_block text-center">
                            <div className="iconbox_icon">
                                <img src={icon3} alt="Ícone de Email" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Email</h3>
                                <p className="mb-0">contato@rodcommerce.com.br</p>
                                <p className="mb-0">suporte@rodcommerce.com.br</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="iconbox_block text-center">
                            <div className="iconbox_icon">
                                <img src={icon4} alt="Ícone de Calendário" />
                            </div>
                            <div className="iconbox_content">
                                <h3 className="iconbox_title">Horário de Atendimento</h3>
                                <p className="mb-0">Seg - Sex: 8h00 - 18h00</p>
                                <p className="mb-0">Sábado: 8h00 - 14h00</p>
                            </div>
                        </div>
                    </div>
                </div>
                {

                    (props.hideContactForm === undefined || props.hideContactForm === false) && (

                        <div className="section_space pb-0">
                            <div className="row justify-content-lg-between">
                                <div className="col-lg-7">
                                    <div className="contact_form mb-0">
                                        <h3 className="details_item_info_title mb-1">Envie uma Mensagem</h3>
                                        <p className="mb-5">
                                            Dê-nos a oportunidade de servir e trazer inovação para o seu negócio.
                                        </p>
                                        <ContactForm />
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="gmap_canvas ps-lg-5">
                                        <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3661.1767417285587!2d-51.899836799999996!3d-23.417981800000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ecd0f729b7431d%3A0x8e77d090f94e83fa!2sR.%20Haiti%2C%20815%20-%20Vila%20Morangueira%2C%20Maring%C3%A1%20-%20PR%2C%2087040-420!5e0!3m2!1spt-BR!2sbr!4v1724458840636!5m2!1spt-BR!2sbr"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </section>
    )
}

export default ContactSection;
