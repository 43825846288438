import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/header/Header';
import Hero from '../../components/hero/hero';
import FeaturesSection from '../../components/FeaturesSection/FeaturesSection';
import About from '../../components/about/about';
import PolicySection from '../../components/PolicySection/PolicySection';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import CtaSection from '../../components/CtaSection/CtaSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';

const HomePage = () => {
    return (
        <Fragment>
            <Helmet>


                <title>RodCommerce - Plataforma de E-commerce para Sucesso</title>

                <meta name="description" content="RodCommerce oferece uma plataforma de e-commerce robusta e flexível, com integração com marketplaces, SEO avançado, e muito mais." />
                <meta name="keywords" content="e-commerce, plataforma de e-commerce, SEO, marketplaces, RodCommerce" />

                <meta charset="UTF-8" />
                <meta name="robots" content="index, follow" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />

                <meta property="og:title" content="RodCommerce - Soluções Flexíveis para Seu E-commerce" />
                <meta property="og:description" content="Na RodCommerce, oferecemos soluções dinâmicas para atender às suas necessidades de e-commerce." />
                <meta property="og:image" content="https://rodcommerce.com/og.png" />
                <meta property="og:url" content="https://www.rodcommerce.com.br" />
                <meta property="og:type" content="website" />


                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="RodCommerce - Soluções Flexíveis para Seu E-commerce" />
                <meta name="twitter:description" content="Conheça as soluções de e-commerce da RodCommerce." />
                <meta name="twitter:image" content="https://rodcommerce.com/og.png" />
                <link rel="canonical" href="https://www.rodcommerce.com.br/" />
                <meta name="robots" content="index, follow" />


            </Helmet>
            <Header />
            <main className="page_content">
                <Hero />
                <FeaturesSection />
                <About />
                <PolicySection />
                <ServiceSection />
                <ProjectSection />
                <Testimonial />
                <CtaSection />
            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePage;
