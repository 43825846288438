import React from 'react';
import CasesSucesso from '../../api/service';
import { Link } from "react-router-dom";

const ServiceSection = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="service_section section_space xb-hidden pb-0">
            <div className="container">
                <div className="heading_block text-center">
                    <div className="heading_focus_text">
                        Serviços em
                        <span className="badge bg-secondary text-white">destaque</span>
                    </div>
                    <h2 className="heading_text mb-0">
                        Nossos Serviços em Destaque
                    </h2>
                </div>

                <div className="row">
                    {CasesSucesso.slice(0, 5).map((service, srv) => (
                        <div className={`${service.col} mt-30`} key={srv}>
                            {service.title ?
                                <div className="service_block">
                                    <div className="service_image">
                                        <img src={service.sImg} alt="Serviço em Destaque" />
                                    </div>
                                    <div className="service_content">
                                        <h3 className="service_title">
                                            <a
                                                href={service.url}
                                                onClick={ClickHandler}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{
                                                    color: '#ffffff', // Cor branca para destacar em fundos escuros
                                                    textDecoration: 'none',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.6)', // Fundo semi-transparente para melhorar o contraste
                                                    padding: '5px 10px', // Espaçamento ao redor do texto para melhorar a legibilidade
                                                    borderRadius: '5px', // Bordas arredondadas para ficar mais estético
                                                    transition: 'color 0.3s ease, background-color 0.3s ease',
                                                }}
                                                onMouseEnter={(e) => {
                                                    e.currentTarget.style.color = '#cccccc'; // Alterar a cor ao passar o mouse
                                                    e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.8)'; // Escurecer o fundo no hover
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.currentTarget.style.color = '#ffffff'; // Voltar para a cor original
                                                    e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'; // Voltar para o fundo original
                                                }}
                                            >
                                                {service.title}
                                            </a>

                                        </h3>
                                        <div className="links_wrapper">
                                            <ul className="category_btns_group unordered_list">
                                                <li><Link onClick={ClickHandler} to={''}>{service.thumb1}</Link></li>
                                                <li><Link onClick={ClickHandler} to={``}>{service.thumb2}</Link></li>
                                                <li>
                                                    <a href={service.url} onClick={ClickHandler} target="_blank" rel="noopener noreferrer">
                                                        {service.url}
                                                    </a>
                                                </li>
                                            </ul>
                                            <a href={service.url} onClick={ClickHandler} target="_blank" rel="noopener noreferrer" className="icon_block">
                                                <i className="fa-regular fa-arrow-up-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                        </div>
                    ))}
                </div>

                <div className="btns_group pb-0 text-center">
                    <Link onClick={ClickHandler} className="btn btn-outline-light" to="/servicos">
                        <span className="btn_label" data-text="Mais Serviços">Mais Serviços</span>
                        <span className="btn_icon">
                            <i className="fa-solid fa-arrow-up-right"></i>
                        </span>
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default ServiceSection;
