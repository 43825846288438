import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import CtaSection from '../../components/CtaSection/CtaSection';
import ContactSection from '../../components/ContactSection';
import { Helmet } from 'react-helmet';

const ContactPage = (props) => {

    return (
        <Fragment>
            <Helmet>
                <title>404 - Não encontrado - RodCommerce - Soluções Flexíveis para Seu E-commerce</title>
                <meta name="description" content="RodCommerce oferece soluções dinâmicas e flexíveis para e-commerce, ajustando serviços às suas necessidades específicas." />
                <meta name="keywords" content="RodCommerce, e-commerce, soluções flexíveis, desenvolvimento de software, 404 - Não encontrado, contatenos, contact" />

                <meta charset="UTF-8" />
                <meta name="robots" content="index, follow" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />

                <meta property="og:title" content="404 - Não encontrado - RodCommerce - Soluções Flexíveis para Seu E-commerce" />
                <meta property="og:description" content="Na RodCommerce, oferecemos soluções dinâmicas para atender às suas necessidades de e-commerce." />
                <meta property="og:image" content="https://rodcommerce.com/og.png" />
                <meta property="og:url" content="https://www.rodcommerce.com.br/404 - Não encontrado" />
                <meta property="og:type" content="website" />


                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="404 - Não encontrado - RodCommerce - Soluções Flexíveis para Seu E-commerce" />
                <meta name="twitter:description" content="Conheça as soluções de e-commerce da RodCommerce." />
                <meta name="twitter:image" content="https://rodcommerce.com/og.png" />
                <link rel="canonical" href="https://www.rodcommerce.com.br/404 - Não encontrado" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <Header />
            <main className="page_content contact-page">
                <PageTitle pageTitle={'Página não encontrada'} pagesub={'404'} pageTop={'Página não encontrada'} />
                <ContactSection 
                    hideContactForm={true}
                />
            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default ContactPage;
