import pImg1 from '../images/cases/sitemrstylishinteiro.png'


import tImg1 from '../images/case/icon_angular.svg'



const Project = [
    {
        Id: '1',
        pImg: pImg1,
        title: 'Mr.Stylish Store - Moda Masculina e Feminina',
        slug: 'https://mrstylishstore.com',
        sub: 'Ecommerce',
        description: 'Mr.Stylish Store é uma referência no e-commerce de moda no Brasil, oferecendo as últimas tendências em roupas e acessórios para o público masculino e feminino. A loja se destaca pela sua vasta seleção de produtos de alta qualidade e um serviço de entrega rápida e segura em Maringá e região. Confira mais em https://www.mrstylishstore.com.',
    },
   

]

export default Project;