import React, { useEffect } from 'react';
import AllRoute from '../router'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4'; // Importa o Google Analytics


const App = () => {

  useEffect(() => {
    // Inicializa o Google Analytics com o seu código de rastreamento
    ReactGA.initialize('G-N3KS931NMJ'); // Substitua pelo seu código de rastreamento

    // Verifica se o protocolo atual é HTTP
    if (window.location.protocol !== 'https:' && window.location.hostname.indexOf('localhost') <= -1) {
      // Redireciona para a versão HTTPS
      window.location.href = 'https://' + window.location.hostname + window.location.pathname + window.location.search;
    }

    // Envia um evento de visualização de página ao carregar a página inicialmente
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []); // O array vazio garante que o useEffect rode apenas uma vez


  return (
    <div className="App" id='scrool'>
      <AllRoute />
      <ToastContainer />
    </div>
  );
}

export default App;