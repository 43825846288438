import React, { Fragment, useState } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import aImg from '../../images/about/foto1.avif'
import aImg2 from '../../images/about/foto2.avif'
import ModalVideo from 'react-modal-video'
import PolicySection from './Policy';
import WhyUs from './WhyUs';
import FeaturesSection from '../../components/FeaturesSection/FeaturesSection';
import TeamSection from '../../components/TeamSection/TeamSection';
import CtaSection from '../../components/CtaSection/CtaSection';
import { Helmet } from 'react-helmet';


const AboutUsPage = (props) => {

    const [isOpen, setOpen] = useState(false)
    return (
        <Fragment>
            <Helmet>
                <title>Sobre Nós - RodCommerce - Soluções Flexíveis para Seu E-commerce</title>
                <meta name="description" content="RodCommerce oferece soluções dinâmicas e flexíveis para e-commerce, ajustando serviços às suas necessidades específicas." />
                <meta name="keywords" content="RodCommerce, e-commerce, soluções flexíveis, desenvolvimento de software, sobre, sobre nos, about" />

                <meta charset="UTF-8" />
                <meta name="robots" content="index, follow" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />

                <meta property="og:title" content="Sobre Nós - RodCommerce - Soluções Flexíveis para Seu E-commerce" />
                <meta property="og:description" content="Na RodCommerce, oferecemos soluções dinâmicas para atender às suas necessidades de e-commerce." />
                <meta property="og:image" content="https://rodcommerce.com/og.png" />
                <meta property="og:url" content="https://www.rodcommerce.com.br/sobre" />
                <meta property="og:type" content="website" />


                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Sobre Nós - RodCommerce - Soluções Flexíveis para Seu E-commerce" />
                <meta name="twitter:description" content="Conheça as soluções de e-commerce da RodCommerce." />
                <meta name="twitter:image" content="https://rodcommerce.com/og.png" />
                <link rel="canonical" href="https://www.rodcommerce.com.br/sobre" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <Header />
            <main className="page_content about-page">
                <PageTitle pageTitle={'Sobre Nós'} pagesub={'Mais sobre a RodCommerce 😃'} pageTop={''} />
                <section className="intro_about_section section_space bg-light">
                    <div className="intro_about_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="image_wrap">
                                        <img src={aImg} alt="RodCommerce - Sobre " />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="image_wrap position-relative">
                                        <img src={aImg2} alt="RodCommerce - Sobre" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="heading_block mb-0">
                            <div className="row justify-content-lg-between">
                                <div className="col-lg-4">
                                    <div className="heading_focus_text">
                                        Sobre
                                        <span className="badge bg-secondary text-white">RodCommerce 🙂</span>
                                    </div>
                                    <h2 className="heading_text mb-0">
                                        Oferecemos Soluções Flexíveis para Seu E-commerce
                                    </h2>
                                </div>
                                <div className="col-lg-6">
                                    <p className="heading_description mb-0">
                                        Na RodCommerce, nos orgulhamos de oferecer soluções dinâmicas e flexíveis para atender às suas necessidades específicas. Nossa abordagem flexível significa que podemos ajustar nossos serviços para acomodar suas demandas em constante evolução, seja na infraestrutura completa de e-commerce, consultoria estratégica ou desenvolvimento de software.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <PolicySection />
                <FeaturesSection />

                <WhyUs />
                <CtaSection />
            </main>
            <Footer />
            <Scrollbar />
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="7e90gBu4pas" onClose={() => setOpen(false)} />
        </Fragment>
    )
};
export default AboutUsPage;
